import React, { useEffect, useState } from 'react';
import {
  Drawer,
  useTheme,
  AppBar,
  Toolbar,
  Typography,
  CssBaseline,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PrivateRoute from '../PrivateRoute';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import Menu from './Menu';
import AddRequestTestContainerLegacy from '../pages/testListPriceLegacy/AddRequestTestContainerLegacy';
import AddRequestTestContainerNew from '../pages/testListPriceNew/AddRequestTestContainer';
import AddDoctorContainer from '../pages/DoctorList/AddDoctorContainer';
import AddPatientContainer from './AddPatientContainer';
import ProcessingContainerLegacy from '../pages/fillRequestTestLegacy/ProcessingContainerLegacy';
import ListContainerLegacy from '../container/ListPatientContainerLegacy';
import ProcessingContainerNew from '../pages/fillRequestTestNew/ProcessingContainer';
import ListContainerNew from '../container/ListPatientContainerNew';
import ProfileLabContainer from '../container/ProfileLabContainer';
import ReportFinanceContainer from '../pages/paymentInfo/container';
import CashFlowContainer from '../pages/CashFlow/container';
import ReportCashContainer from '../pages/ReportCash/container';
import DoctorReportContainer from '../pages/DoctorReport/container';
import RegisterBillsContainer from '../pages/RegisterBills/container';
import AddResult from './AddResult';
import ReferenceContainer from '../container/ReferenceContainer';
import AddTestContainer from '../container/AddTestContainer';
import TestRequestContainerLegacy from '../pages/testRequestLegacy/container';
import TestRequestContainerNew from '../pages/testRequestNew/container';
import LoginContainer from '../pages/login/container';

import Logout from './Logout';
import Notification from './Notification';
import { SessionProvider } from '../shared/contexts/session';
import { ValidateToken } from '../Services/UserService';

import styles from './styles';

const useStyles = makeStyles(styles);
const App = ({ loadLabInfo, profile_lab }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const [isAuthenticated, setAuthenticated] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const validateToken = async () => {
    try {
      const res = await ValidateToken();
      setAuthenticated(true);
    } catch (error) {
      localStorage.clear();
      setAuthenticated(false);
    }
  };

  useEffect(() => {
    validateToken();
    loadLabInfo();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      loadLabInfo();
    }
  }, isAuthenticated);

  return (
    <Router>
      <SessionProvider value={{ isAuthenticated, setAuthenticated }}>
        <Notification lab={profile_lab.lab} />
        {isAuthenticated ? (
          <>
            <div className={classes.root}>
              <CssBaseline />
              <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                  <Typography variant="h6" noWrap>
                    Sfenix
                  </Typography>
                </Toolbar>
              </AppBar>
              <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                  paper: classes.drawerPaper,
                }}
              >
                <Toolbar />
                <div className={classes.drawerContainer}>
                  <Menu lab={profile_lab.lab} />
                </div>
              </Drawer>
              <main className={classes.content}>
                <Toolbar />
                <Switch>
                  <PrivateRoute exact path="/homeLegacy">
                    <AddRequestTestContainerLegacy />
                  </PrivateRoute>
                  <PrivateRoute exact path="/newHome">
                    <AddRequestTestContainerNew />
                  </PrivateRoute>

                  <PrivateRoute path="/addResult">
                    <AddResult />
                  </PrivateRoute>

                  <PrivateRoute path="/createDoctor">
                    <AddDoctorContainer />
                  </PrivateRoute>

                  <PrivateRoute path="/createPatient">
                    <AddPatientContainer />
                  </PrivateRoute>

                  <PrivateRoute path="/processingLegacy">
                    <ProcessingContainerLegacy />
                  </PrivateRoute>
                  <PrivateRoute path="/newProcessing">
                    <ProcessingContainerNew />
                  </PrivateRoute>

                  <PrivateRoute path="/listLegacy">
                    <ListContainerLegacy />
                  </PrivateRoute>
                  <PrivateRoute path="/newList">
                    <ListContainerNew />
                  </PrivateRoute>

                  <PrivateRoute path="/profileLab">
                    <ProfileLabContainer />
                  </PrivateRoute>

                  <PrivateRoute path="/reference/:test_id">
                    <ReferenceContainer />
                  </PrivateRoute>

                  <PrivateRoute path="/results_legacy/:test_request_id">
                    <TestRequestContainerLegacy />
                  </PrivateRoute>
                  <PrivateRoute path="/newResults/:test_request_id">
                    <TestRequestContainerNew />
                  </PrivateRoute>

                  <PrivateRoute path="/add_test/:category_id">
                    <AddTestContainer />
                  </PrivateRoute>

                  <PrivateRoute path="/paymentInfo">
                    <ReportFinanceContainer />
                  </PrivateRoute>

                  <PrivateRoute path="/cashflow">
                    <CashFlowContainer />
                  </PrivateRoute>

                  <PrivateRoute path="/reportCashflow">
                    <ReportCashContainer />
                  </PrivateRoute>

                  <PrivateRoute path="/doctor-report">
                    <DoctorReportContainer />
                  </PrivateRoute>

                  <PrivateRoute path="/registerBills">
                    <RegisterBillsContainer />
                  </PrivateRoute>

                  <PrivateRoute path="/logout">
                    <Logout />
                  </PrivateRoute>
                </Switch>
              </main>
            </div>
          </>
        ) : (
          <LoginContainer />
        )}
      </SessionProvider>
    </Router>
  );
};

export default App;

import React, { useState } from 'react';
import {
  Grid,
  TextField,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Paper,
} from '@material-ui/core';
import TableDoctor from '../../Components/TableDoctor';
import Layout from '../../layout';
import { Portlet, PortletContent } from '../../Components';

const AddDoctor = ({ onSave, doctors }) => {
  const [fullName, setFullName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [sex, setSex] = useState(null);
  const [phonenumber, setPhonenumber] = useState(null);
  const [email, setEmail] = useState(null);

  const handleFullName = ev => {
    setFullName(ev.target.value);
  };

  const handleLastName = ev => {
    setLastName(ev.target.value);
  };

  const handleSex = ev => {
    setSex(ev.target.value);
  };

  const handlePhonenumber = ev => {
    setPhonenumber(ev.target.value);
  };

  const handleEmail = ev => {
    setEmail(ev.target.value);
  };

  const handleClick = ev => {
    ev.preventDefault();

    let doctor = {
      full_name: fullName,
      last_names: lastName,
      sex: sex,
      phonenumber: phonenumber,
      email: email,
    };
    onSave(doctor);
  };

  return (
    <Layout>
      <Portlet>
        <PortletContent>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={3}
          >
            <Grid xs={12}>
              <h2>Agregar Doctor</h2>
            </Grid>

            <Grid item xs={4}>
              <TextField
                id="outlined-names"
                label="Nombres"
                variant="outlined"
                type="text"
                name="name"
                value={fullName}
                onChange={handleFullName}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-lastnames"
                label="Apellidos"
                variant="outlined"
                type="text"
                name="lastname"
                value={lastName}
                onChange={handleLastName}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Sexo</FormLabel>
                <RadioGroup
                  aria-label="sexo"
                  name="sexo"
                  value={sex}
                  onChange={handleSex}
                >
                  <FormControlLabel
                    value="female"
                    control={<Radio />}
                    label="Mujer"
                  />
                  <FormControlLabel value="male" control={<Radio />} label="Varon" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-phonenumber"
                label="Numero de telefono"
                variant="outlined"
                type="text"
                name="phonenumber"
                value={phonenumber}
                onChange={handlePhonenumber}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-email"
                label="Emaill"
                variant="outlined"
                type="text"
                name="phonenumber"
                value={email}
                onChange={handleEmail}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Button color="primary" variant="contained" onClick={handleClick}>
                Agregar Doctor
              </Button>
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={3}
          >
            <Grid item xs={12}>
              <h2>Lista de Doctores</h2>
              <TableDoctor doctors={doctors} />
            </Grid>
          </Grid>
        </PortletContent>
      </Portlet>
    </Layout>
  );
};

export default AddDoctor;
